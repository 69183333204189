<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">
        Danh sách Instant Feedback
      </h1>
    </div>
    <div class="pt-5 pl-5 pr-5">
      <v-btn
        rounded
        color="primary"
        class="mb-5"
        x-small
        large
        @click="openDiaLogInstantFeedback"
      >
        <v-icon color="white" dark>mdi-plus</v-icon
        ><span style="color: white">Thêm Instant feedback</span>
      </v-btn>
      <div>
        <v-col cols="12">
          <div class="table-responsive">
            <table
              class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
            >
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Tổng sô khóa học</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(instant_feedback, i) in instant_feedbacks">
                  <tr :key="i">
                    <td scope="row">{{ i + 1 }}</td>
                    <td>
                      <p
                        class="text-dark-75 font-weight-bolder d-block font-size-lg mb-0"
                      >
                        {{ instant_feedback.name }}
                      </p>
                    </td>
                    <td>
                      <p
                        class="text-dark-75 font-weight-bolder d-block font-size-lg mb-0"
                      >
                        {{ instant_feedback.courses.length }}
                      </p>
                    </td>
                    <td>
                      <div style="display: flex">
                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <button
                              class="btn btn-icon btn-light-primary btn-sm"
                              v-bind="attrs"
                              v-on="on"
                              @click="editInstantFeedback(i)"
                            >
                              <span
                                class="svg-icon svg-icon-md svg-icon-primary"
                              >
                                <v-icon color=""
                                  >mdi-square-edit-outline</v-icon
                                >
                              </span>
                            </button>
                          </template>
                          <span>Edit</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <router-link
                              :to="{
                                name: 'InstantFeedbackDetail',
                                params: { id: instant_feedback.id },
                              }"
                              target="_blank"
                            >
                              <button
                                class="btn btn-icon btn-light-success btn-sm ml-4"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-comment-quote</v-icon>
                                </span>
                              </button>
                            </router-link>
                          </template>
                          <span>Instant feedback detail</span>
                        </v-tooltip>
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <button
                              class="btn btn-icon btn-light-danger btn-sm ml-4"
                              v-bind="attrs"
                              v-on="on"
                              @click="
                                deleteInstantFeedback(instant_feedback.id)
                              "
                            >
                              <span
                                class="svg-icon svg-icon-md svg-icon-primary"
                              >
                                <v-icon color="">mdi-delete</v-icon>
                              </span>
                            </button>
                          </template>
                          <span>Delete</span>
                        </v-tooltip>
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-if="!instant_feedbacks.length">
                  <tr>
                    <td colspan="3" style="text-align: center">No Data</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </v-col>
        <v-col cols="6">
          <v-pagination
            style="float: left"
            color="#00BCD4"
            v-model="data_pagination.current_page"
            :length="data_pagination.total_pages"
            :total-visible="6"
          ></v-pagination>
        </v-col>
      </div>
    </div>

    <v-row justify="center">
      <v-dialog
        v-model="dialogInstantFeedback"
        max-width="1000px"
        scrollable
        persistent
        :retain-focus="false"
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span v-if="type_form === 'add'" class="headline"
                >Add Instant Feedback</span
              >
              <span v-if="type_form === 'update'" class="headline"
                >Edit Instant Feedback</span
              >
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogInstantFeedback = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Name:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4"
                      ><v-text-field
                        dense
                        v-model="name_input"
                        required
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Chọn khóa học:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-autocomplete
                        v-model="course_ids"
                        :items="unselected_course_list"
                        item-text="name.vi"
                        item-value="id"
                        solo
                        chips
                        placeholder="Chọn khóa học"
                        multiple
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'add'"
              @click="btnAddOrEdit(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'update'"
              @click="btnAddOrEdit(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogInstantFeedback = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import Swal from "sweetalert2";
export default {
  name: "InstantFeedbackList",
  components: {
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      is_call_api: false,
      data_pagination: {
        current_page: 1,
        total_pages: 1,
      },
      instant_feedbacks: [],
      type_form: null,
      dialogInstantFeedback: false,
      name_input: null,
      feedback_id: null,
      index_feedback: null,
      course_tags: [],
      course_ids: [],
      unselected_course_list: [],
    };
  },
  watch: {
    dialogInstantFeedback(val) {
      if (!val) {
        this.unselected_course_list = JSON.parse(
          JSON.stringify(this.course_tags)
        );
      }
    },
  },
  created() {
    this.getAllInstantFeedback();
    this.getAllCourse();
  },
  methods: {
    async getAllCourse() {
      let vm = this;
      await ApiService.get("prep-app/courses/limit").then(function (response) {
        if (response.status === 200) {
          vm.course_tags = response.data;
          vm.unselected_course_list = response.data;
        }
      });
    },
    async getAllInstantFeedback(page = 1) {
      let vm = this;
      try {
        vm.is_call_api = true;
        let res = await ApiService.get(
          "prep-app/instant-feedback?page=" + page
        );
        if (res.status === 200) {
          vm.instant_feedbacks = res.data.data;
          vm.data_pagination = res.data.meta.pagination;
          vm.is_call_api = false;
        }
      } catch (error) {
        vm.is_call_api = false;
        console.log(error);
      }
    },
    openDiaLogInstantFeedback() {
      this.name_input = "";
      this.course_ids = [];
      this.type_form = "add";

      // lọc các khóa học chưa chọn bởi các instant feedback nào
      let convert_instant_feedbacks = JSON.parse(
        JSON.stringify(this.instant_feedbacks)
      );
      let selected_course_list = convert_instant_feedbacks
        .map((item) => {
          return item.courses.map((course) => course.id);
        })
        .flat(1)
        .filter((item, i, ar) => ar.indexOf(item) === i);
      for (let i = 0; i < selected_course_list.length; i++) {
        let index_remove = this.unselected_course_list.findIndex(
          (item) => item.id === selected_course_list[i]
        );
        this.unselected_course_list.splice(index_remove, 1);
      }

      this.dialogInstantFeedback = true;
    },
    validateData() {
      let flat = true;
      if (this.name_input === "" || this.name_input == null) {
        this.$toasted.error("Chưa nhập tiêu đề !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.course_ids.length <= 0) {
        this.$toasted.error("Hãy chọn ít nhất 1 khóa học !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      return flat;
    },
    async btnAddOrEdit(type) {
      let validateQues = this.validateData();
      if (!validateQues) {
        return;
      }
      if (type === 2) {
        let validateCourse = this.validateCourseSelected();
        if (!validateCourse) {
          return;
        }
      }
      let vm = this;
      let data = {
        name: this.name_input,
        course_ids: this.course_ids,
      };
      if (type === 1) {
        vm.is_call_api = true;
        try {
          let res = await ApiService.post("prep-app/instant-feedback", data);
          if (res.status === 200) {
            vm.getAllInstantFeedback(vm.data_pagination.current_page);
            vm.$toasted.success(" Thêm thành công !", {
              theme: "toasted-primary",
              position: "top-right",
              duration: 4000,
            });
            vm.dialogInstantFeedback = false;
            vm.is_call_api = false;
          }
        } catch (error) {
          vm.is_call_api = false;
          if (error.response.status === 422) {
            let data_response_error =
              error.response.data.error.message.split(".<br />");
            vm.showError(data_response_error);
          }
        }
      } else {
        try {
          let res = await ApiService.put(
            "prep-app/instant-feedback/" + vm.feedback_id,
            data
          );
          if (res.status === 202) {
            vm.getAllInstantFeedback(vm.data_pagination.current_page);
            vm.$toasted.success("Sửa thành công !", {
              theme: "toasted-primary",
              position: "top-right",
              duration: 4000,
            });
            vm.dialogInstantFeedback = false;
            vm.is_call_api = false;
          }
        } catch (error) {
          vm.is_call_api = false;
          if (error.response.status === 422) {
            let data_response_error =
              error.response.data.error.message.split(".<br />");
            vm.showError(data_response_error);
          }
        }
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    async editInstantFeedback(index) {
      let data = this.instant_feedbacks[index];
      this.index_feedback = index;
      this.name_input = data.name;
      this.feedback_id = data.id;
      this.type_form = "update";
      this.course_ids = await data.courses.map((e) => e.id);
      // await this.setUnselectedCourseList(index);
      this.dialogInstantFeedback = true;
    },
    validateCourseSelected() {
      let flat = true;
      let vm = this;
      let convert_instant_feedbacks = JSON.parse(
        JSON.stringify(this.instant_feedbacks)
      );
      let selected_course_list = convert_instant_feedbacks
        .map((item) => {
          return item.courses.map((course) => {
            return {
              id: course.id,
              name: course.name.vi,
            };
          });
        })
        .filter((item, index) => {
          return index !== vm.index_feedback;
        })
        .flat(1);
      let selected_course_unique = [
        ...new Map(
          selected_course_list.map((item) => [item["id"], item])
        ).values(),
      ];
      if (selected_course_unique.length) {
        let check_selected_course = selected_course_unique.filter((course) => {
          return vm.course_ids.indexOf(course.id) !== -1;
        });
        if (check_selected_course.length > 0) {
          for (let i = 0; i < check_selected_course.length; i++) {
            this.$toasted.error(
              "Khóa " +
                check_selected_course[i].name +
                " đã được gắn bởi instant feedback khác",
              {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              }
            );
            flat = false;
          }
        }
      }
      return flat;
    },
    deleteInstantFeedback(feedback_id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.delete("prep-app/instant-feedback/" + feedback_id)
            .then(function (res) {
              if (res.status === 202) {
                vm.$toasted.success("Delete successfully!!", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.getAllInstantFeedback(vm.data_pagination.current_page);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.btn.btn-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
  border-color: transparent;
}
.btn.btn-light-success i {
  color: #3699ff;
}
.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #3699ff;
}
</style>
